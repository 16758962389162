<template>
	<div>
		<!-- Post is a link -->
		<div v-if="post.link_id >0">
			<!-- Check if link data exists -->
			<div v-if="post.link_data != null">
				<!-- Image -->
				<div v-if="post.link_data.cover_image != null && post.link_data.cover_image != ''" class="margin-bottom-md">
					<a :href="post.link_data.url" target="_blank" class="text-cap" style="text-decoration:none;">
						<ion-img :src="post.link_data.cover_image" width="100%" />
					</a>
				</div>
				<!-- Title -->
				<div v-if="post.link_data.title != null && post.link_data.title != ''" class="margin-bottom-md">
					<a :href="post.link_data.url" target="_blank" class="text-cap" style="text-decoration:none;">
						<h4>{{post.link_data.title}}</h4>
					</a>
				</div>
				<!-- Description -->
				<div v-if="post.link_data.description != null && post.link_data.description != ''" class="margin-bottom-md">
					{{post.link_data.description}}
				</div>
				<!-- URL -->
				<div v-if="post.link_data.url != null && post.link_data.url != ''">
					<a :href="post.link_data.url" target="_blank" style="text-decoration:none!important;">
						{{post.link_data.url}}
					</a>
				</div>

			</div>
			<!-- There is no link data -->
			<div v-else class="padding-md">
				Something went wrong.
			</div>
		</div>

		<!-- Buttons -->
		<ion-item>
			<div tabindex="0"></div>
			<ion-button slot="end" color="danger" @click.prevent="deletePost()">
				Delete
			</ion-button>
		</ion-item>
	</div>
</template>

<script>
import { IonImg, IonItem, IonButton } from '@ionic/vue';
import { defineComponent, computed, onMounted, ref } from 'vue';
import { useStore } from "vuex";
import apiClient from '../services/api';

export default defineComponent({
	name: 'PageEdit',
	props: ['postId'],
	components: {
		IonImg, IonItem, IonButton
	},
	setup(props) {
		const store = useStore();
		const authUser = computed(() => store.state.authUser);
		const post = ref([]);

		onMounted(() => {
			getPost()
		})

		function getPost() {
			//presentLoading()
			apiClient.get('/api/get/post/'+props.postId, {
				headers: {
					'Authorization':  `Bearer ${store.state.authToken}`
				}
			}).then(response => {
				post.value = response.data

			}).catch(error => {
				console.log(error)  
			});      
		}

		function deletePost() {
			//presentLoading()
			apiClient.get('/api/destroy/post/'+props.postId, {
				headers: {
					'Authorization':  `Bearer ${store.state.authToken}`
				}
			}).then(response => {
				if(response.data.message == 'success') {
					window.location = '/admin'
				}

			}).catch(error => {
				console.log(error)  
			}); 			
		}

		return {
			authUser, post, deletePost
		}
	}
});
</script>

