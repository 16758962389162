<template>
  <ion-page>
    <!-- Header -->
    <ion-header id="dark-header">
      <ion-toolbar class="text-center">
        <ion-buttons slot="start">
          <ion-button @click.prevent="goBack()">
            <ion-icon :icon="chevronBack"></ion-icon>
          </ion-button>
        </ion-buttons>

        <ion-title>Edit Post</ion-title>
      </ion-toolbar>
    </ion-header> 
    <!-- Page Content -->
    <ion-content>
      <!--Authorized -->
      <div v-if="isAdmin === true" class="app-max-width">
        <ion-toolbar color="light">
          <ion-segment scrollable :value="view">
            <ion-segment-button value="post" @click="view = 'post'">
              Post
            </ion-segment-button>
            <ion-segment-button value="genres" @click="view = 'genres'">
              Genres
            </ion-segment-button>
            <ion-segment-button value="scenes" @click="view = 'scenes'">
              Scenes
            </ion-segment-button>
            <ion-segment-button value="pages" @click="view = 'pages'">
              Pages
            </ion-segment-button>
          </ion-segment>
        </ion-toolbar>

        <!-- Wait for page id -->
        <div>
          <!-- Page Form -->
          <div v-if="view == 'post' && postId > 0" class="padding-md">
            <PostEdit :post-id="postId" />
          </div>
          <!-- Page Form -->
          <div v-if="view == 'genres'" class="padding-md">
            <PostGenres :post-id="postId" />
          </div>
          <!-- Page Photo -->
          <div v-if="view == 'scenes'" class="padding-md">
            <PostScenes :post-id="postId" />
          </div>
          <!-- Page Genres -->
          <div v-if="view == 'pages'" class="padding-md">
           <UpdatePostPages :post-id="postId" />
          </div>
        </div>
      </div>
      <!-- Not authorized -->
      <div v-else class="app-max-width">
        Not authorized
      </div>
    </ion-content>

  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, IonTitle, IonSegment, IonSegmentButton } from '@ionic/vue';
import { chevronBack, add, reorderThree, checkmark } from 'ionicons/icons';
import { defineComponent, ref, onMounted } from 'vue';
import doAuth from '../../services/auth';
import { useRouter } from 'vue-router';
import { useStore } from "vuex";
import PostEdit from '../../components/PostEdit.vue';
import PostGenres from '../../components/PostGenres.vue';
import PostScenes from '../../components/PostScenes.vue';
import UpdatePostPages from '../../components/UpdatePostPages.vue';

export default defineComponent({
  name: 'AdminPostEdit',
  components: {
    IonPage, IonContent, IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, IonTitle, IonSegment, IonSegmentButton, PostEdit, PostGenres, PostScenes, UpdatePostPages 
  },
  data() {
    return {
      isAuth: false
    }
  },
  beforeMount: function() {
    this.isAuth = doAuth()
  },
  setup() {
    const router = useRouter();
    const isAdmin = ref(true);
    const postId = ref(0);
    const view = ref('post');
    const store = useStore();

    onMounted(() => {
      postId.value = router.currentRoute._rawValue.params.id
    })

    function updateRoute(val) {
      router.push(val)
    }

    function goBack() {
      router.go(-1)
    }

    return {
      chevronBack, add, router, updateRoute, goBack, isAdmin, reorderThree, postId, view, checkmark, store
    }
  }
});
</script>

<style scoped>

</style>