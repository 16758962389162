<template>
	<div>
		<div>
			<div class="padding-bottom-md text-center">
				<ion-button @click.prevent="updatePostPages()">
					Save
				</ion-button>
			</div>
			<div>
				<SelectPage @get-the-page="getPage" />
			</div>
			<div v-if="pages.length > 0" class="padding-top-md">
				<div v-for="(page, index) in pages" :key="page" class="margin-bottom-md border-bottom">
					<div class="padding-left-md padding-right-md">
						{{page.name}}
						<div class="padding-top-md">
							<ion-label position="stacked">Page Role</ion-label>
							<ion-select placeholder="Select One" v-model="page.role_id">
								<ion-select-option :value="1">Author of post</ion-select-option>
								<ion-select-option :value="2">Venue</ion-select-option>
								<ion-select-option :value="3">Artist</ion-select-option>
								<ion-select-option :value="4">Host</ion-select-option>
								<ion-select-option :value="5">Mentioned in post</ion-select-option>
							</ion-select>		
						</div>
						<div class="padding-top-md padding-bottom-md text-right">
							<ion-button color="danger" @click.prevent="removePage(index)">
								Remove Page
							</ion-button>
						</div>
					</div>
				</div>
			</div>
			<div v-else>
				No pages
			</div>
		</div>
	</div>
</template>

<script>
import { IonLabel, IonSelect, IonSelectOption, loadingController, toastController } from '@ionic/vue';
import { defineComponent, computed, ref, onMounted } from 'vue';
import { useStore } from "vuex";
import SelectPage from './SelectPage.vue';
import apiClient from '../services/api';

export default defineComponent({
	name: 'UpdatePostPages',
	props: ['postId'],
	components: {
		IonLabel, IonSelect, IonSelectOption, SelectPage
	},
	setup(props) {
		const store = useStore();
		const authUser = computed(() => store.state.authUser);
		const pages = ref([]);
		const toastMessage = ref(null);
		const timeout = { default: 6000 }

		onMounted(() => {
			getPages()
		})

		function getPage(item) {
			pages.value.push({
				id: item.id,
				name: item.name,
				slug: item.slug,
				image: item.image,
				status: 1,
				role_id: null
			})
		}

		function getPages() {

			apiClient.get('/api/get/post/pages/'+props.postId,
			{
				headers: {
					'Authorization':  `Bearer ${store.state.authToken}`
				}
			}).then(response => {
				if(response.data.length > 0) {
					for(var i=0;i<response.data.length;i++) {
						pages.value.push({
							id: response.data[i].id,
							name: response.data[i].name,
							slug: response.data[i].slug,
							image: response.data[i].image,
							status: response.data[i].status,
							role_id: response.data[i].role_id
						})						
					}
				}
			}).catch(error => {
				console.log(error)
			}); 			
		}

		async function presentLoading() {
			const loading = await loadingController
			.create({
				cssClass: 'my-custom-class',
				message: 'Please wait...',
				duration: timeout,
			});

			await loading.present();

			setTimeout(function() {
				loading.dismiss()
			}, timeout);
		}

		async function openToast() {
			const toast = await toastController
			.create({
				//message: '',
				duration: 6000,
				message: toastMessage.value,
				position: 'bottom',
				buttons: [
				{
					icon: 'close',
					role: 'cancel',
					handler: () => {

					}
				}]
			})
			return toast.present();
		}

		function updatePostPages() {
			presentLoading()
			apiClient.post('/api/update/post-pages/'+props.postId, {
				pages: pages.value      
			},
			{
				headers: {
					'Authorization':  `Bearer ${store.state.authToken}`
				}
			}).then(response => {
				// Success
				if(response.data.message == 'success') {
					toastMessage.value = 'Your post has been updated.'
					openToast()
				}
				// Fail Validation
				if(response.data.message == 'fail-validation') {
					toastMessage.value = 'There was a problem.'
					openToast()        
				}
				// Fail Auth
				if(response.data.message == 'fail-auth') {
					toastMessage.value = 'You must be signed in to update your profile.'
					openToast()
				}
			}).catch(error => {
				// credentials didn't match
				console.log(error)
			});        
		}

		function removePage(i) {
			pages.value.splice(i,1)
		}

		return {
			authUser, store, updatePostPages, getPage, pages, removePage
		}
	}
});
</script>